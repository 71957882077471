@import "../../bower/normalize-scss/_normalize";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "fonts.scss";

$blue: #008aaf;
$black: #2b2c2b;
$contactBg: #464646;

$bebasBold: "bebas_neuebold";
$poppinsRegular: "poppinsregular";
$poppinsMedium: "poppins_mediumregular";
$poppinsBold: "poppinsbold";
$poppinsSemiBold: "poppins_semiboldregular";
html, body {
	height: 100%;
	font-family: $poppinsRegular;
	color: $black;
}
body {
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}
a, a:hover, a:focus {
	background:transparent !important;
	color: inherit;
	text-decoration: none;
}
ul {
	padding-left: 0;
}
li {
	list-style: none;
}
.navbar {
	background: transparent;
	border: none;
	min-height: 80px;
	transition: background .15s ease-in-out;
	.navbar-brand {
			height: 80px;
			display: flex;
			align-items: center;
 			padding: 0 15px;
		img {
			max-width: 180px;
			object-position:center;
			object-fit: cover;
		}
	}
	
	&.white {
		background: #faf9f9;
		-webkit-box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.3);
		-moz-box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.3);
		box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.3);
		.navbar-toggle{
			.icon-bar{
				background-color:#464646;
			}
		}
	}
	.navbar-nav {
		hr{
			position: absolute;
			left: 0;
			right: 0;
			height: 3px;
			width: 0%;
			background-color: #ffb000;
			bottom: 0px;
			z-index: 3;
			margin: 0 !important;
			transition: all 0.3s ease-in;
		}
		li {
			.aboutmenu{
				display:none;
			}
			.servicesmenu{
				display:none;
			}
			.newsmenu{
				display:none;
			}
			a {
				font-family: $poppinsBold;
				padding-top: 30px;
				padding-bottom: 30px;
				color: #2e75b5;
				&:hover, &.active, &:focus {
					color: #2e75b5 !important;
				}
			}
			&:hover{
				.aboutmenu{
					position: absolute;
					display: block;
					top: 100%;
					left: 0;
					z-index: 1000;
					float: left;
					min-width: 200px;
					// padding: 5px 0;
					margin: 2px 0 0;
					font-size: 14px;
					text-align: left;
					background-color: #fff;
					background-clip: padding-box;
					border-radius: 4px;
					box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
					li{
						padding:10px 30px;
						&:hover,&:active{
							a{
								color:white;
								&:hover,&:active{
									color:white !important;
								}
							}
							color:white;
							background:#008aaf;
						}
					}
				}
				.newsmenu{
					position: absolute;
					display: block;
					top: 100%;
					left: 0;
					z-index: 1000;
					float: left;
					min-width: 200px;
					// padding: 0 0;
					margin: 2px 0 0;
					font-size: 14px;
					text-align: left;
					background-color: #fff;
					background-clip: padding-box;
					border-radius: 4px;
					box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
					li{
						padding:10px 30px;
						&:hover,&:active{
							a{
								color:white;
								&:hover,&:active{
									color:white !important;
								}
							}
							color:white;
							background:#008aaf;
						}
					}
				}
				.servicesmenu{
					position: absolute;
					display: block;
					top: 100%;
					left: 0;
					z-index: 1000;
					float: left;
					min-width: 200px;
					// padding: 5px 0;
					margin: 2px 0 0;
					font-size: 14px;
					text-align: left;
					background-color: #fff;
					background-clip: padding-box;
					border-radius: 4px;
					box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
					li{
						padding:10px 30px;
						&:hover,&:active{
							a{
								color:white;
								&:hover,&:active{
									color:white !important;
								}
							}
							background:#008aaf;
						}
					}
				}
			}
		}
	}
	.navbar-header {
		height: 80px;
	}
	.navbar-collapse {
		&.in {
			background: #faf9f9;
			li {
				a {
					padding: 10px;
				}
			}
		}
		
	}
	.navbar-toggle {
		margin-top: 20px;
		border: none;
	}
}
.btn {
	font-family: $poppinsMedium;
	font-size: 15px;
	line-height: 40px;
	padding-left: 40px;
	padding-right: 40px;
	border-radius: 0;
	outline: none;
	transition: all ease-in 0.2s;
	&.btn-blue {
		background: $blue;
		color: #ffffff;
		&:hover {
			background: #00acdb;
		}
	}
	&.btn-white {
		background: #ffffff;
		color: #464646;
	}
	&.btn-red {
		background: #ee2d25!important;
		color: #ffffff;
	}
}
.mdc-text-field {
	background: transparent!important;
	width: 100%;
	label {
		color: #ffffff!important;
	}
	input {
		border-bottom-color: #ffffff!important;
		background: transparent!important;
	}
}
.grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.home-page {
	hr {
		max-width: 100px;
		height: 4px;
		background: $blue;
		border-top: none;
		margin-left: 0;
	}
	section {
		min-height: 100vh;
		.section-header, .text-content {
			.title {
				font-family: $poppinsBold;
				text-transform: uppercase;
				font-size: 20px;
			}
		}
		h1 {
			p {
				line-height: 1;
			}
		}
		p {
			line-height: 2;
			strong {
				font-family: $poppinsBold;
			}
		}
	}
	.hero-banner {
		padding-top: 0;
		.hero-bg {
			padding-top: 90px;
			height: 90%;
			width: 100%;
			background-image: url('../img/app/section-1-bg.jpg');
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			position: absolute;
		}
		.container {
			position: relative;
			padding-top: 90px;
			min-height: 100vh;
			.grid {
				min-height: 100vh;
				grid-gap: 50px;
			}
			.text-content {
				margin-top: -200px;
				align-self: center;				
				h1 {
					font-family: $bebasBold;
					font-size: 4.5em;
					p {
						margin-bottom: 0;
					}
				}
				h4 {
					font-size: 16px;
					font-family: $poppinsRegular;
					margin-top: 20px;
					margin-bottom: 40px;
					max-width: 75%;
				}
			}
			.image {
				background-repeat: no-repeat!important;
				background-size: cover!important;
				background-position: center center!important;
				-webkit-box-shadow: -2px 0px 10px 2px rgba(0,0,0,0.5);
				-moz-box-shadow: -2px 0px 10px 2px rgba(0,0,0,0.5);
				box-shadow: -2px 0px 10px 2px rgba(0,0,0,0.5);
			}
		}
	}
	.profile-section {
		.container {
			min-height: 100vh;
			.grid {
				grid-gap: 50px;
				min-height: 88vh;
 				>div {
					align-self: center;
				}
			}
			.image {
				height: 100%;
				max-height: 60%;
				background-repeat: no-repeat;
				background-size: cover!important;
				background-position: center center!important;
			}
		}
	}
	.services-section {
		position: relative;
		.service-bg {
			padding-top: 90px;
			height: 100vh;
			width: 100%;
			background-image: url('../img/app/section-3-bg.jpg');
			background-size: cover;
			background-position: top center;
			background-repeat: no-repeat;
			position: absolute;
			bottom: 0;
		}
		.container {
			position: relative;
			height: 100%;
			.grid {
				grid-gap: 50px;
				grid-template-columns: 350px 1fr;
				height: 100%;
				.text-content {
					align-self: center;
				}
			}
		}
		.text-content {
			ul {
				padding-left: 15px;
				li {
					list-style: disc;
					line-height: 1.5;
					ul {
						padding-left: 30px;
						li{
							list-style: none;
						}
						li:before {
							content: "\f00c";
  							font-family: 'Font Awesome 5 Pro';
							display: inline-block;
							margin-left: -1.3em;
							width: 1.3em;
						}
					}
				}
			}
		}
		.services {
			height: 95vh;
			margin-top: -50px;
			.grid {
				grid-template-columns: 1fr 1fr;
			}
			.service {
				text-align: center;
				height: 100%;
				color: #ffffff;
				text-transform: uppercase;
				display: grid;
				padding: 25px;
				background: rgba(76,76,76,0.1);
				background: -moz-linear-gradient(top, rgba(76,76,76,0.1) 0%, rgba(70,70,70,0.8) 100%);
				background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(76,76,76,0.1)), color-stop(100%, rgba(70,70,70,0.8)));
				background: -webkit-linear-gradient(top, rgba(76,76,76,0.1) 0%, rgba(70,70,70,0.8) 100%);
				background: -o-linear-gradient(top, rgba(76,76,76,0.1) 0%, rgba(70,70,70,0.8) 100%);
				background: -ms-linear-gradient(top, rgba(76,76,76,0.1) 0%, rgba(70,70,70,0.8) 100%);
				background: linear-gradient(to bottom, rgba(76,76,76,0.1) 0%, rgba(70,70,70,0.8) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#464646', GradientType=0 );
				.name {
					font-family: $bebasBold;
					font-size: 30px;
					align-self: flex-end;
				}
				.link {
					display: none;
					i {
						padding-left: 5px;
					}
				}
			}
			.image {
				background-size: cover!important;
				background-position: center center!important;
				background-repeat: no-repeat;
				-webkit-box-shadow: -2px 0px 10px 2px rgba(0,0,0,0.5);
				-moz-box-shadow: -2px 0px 10px 2px rgba(0,0,0,0.5);
				box-shadow: -2px 0px 10px 2px rgba(0,0,0,0.5);
				&:hover {
					a {
						display: block;
					}
				}
			}
		}
		
	}
	.management-section {
		padding-top: 50px;
		background: #faf9f9;
		margin-bottom: 150px;
		.managers {
			margin-top: 50px;
			.manager {
				margin-bottom: 40px;
				display: grid;
				grid-template-columns: 250px 1fr;
    			grid-gap: 50px;
				
				.image {
					border-radius: 100%;
					width: 250px;
					height: 250px;
					background-position: center center!important;
					background-repeat: no-repeat!important;
					background-size: cover!important;
				}
			}
		}
	}
	.contact-section {
		min-height: unset;
		height: auto;
		width: 95%;
		margin: 0 auto;
		background-image: url('../img/app/form-bg.jpg');
		background-size: cover;
		background-position: center center;
		padding: 50px 0;
		.section-header {
			background: #464646;
			padding: 40px;
			margin-top: -140px;
			-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
			-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
			.grid {
				margin-top: 0;
			}
			h1 {
				font-family: $bebasBold;
				color: #ffffff;
				p {
					margin-bottom: 0;
				}
			}
			
		}
		.grid {
			grid-gap: 50px;
			margin-top: 50px;
			.form-container {
				align-self: center;
			}
		}
		.text-content {
			color: #ffffff;
			hr{
				max-width: 100px;
				height: 4px;
				background: #008aaf;
				border-top: none;
				margin-left: 0;
			}
		}
	}
}
.contact-section {
	min-height: unset;
	height: auto;
	width: 95%;
	margin: 250px auto auto auto;
	background-image: url('../img/app/form-bg.jpg');
	background-size: cover;
	background-position: center center;
	padding: 50px 0;
	.section-header {
		background: #464646;
		padding: 40px;
		margin-top: -140px;
		-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
		-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
		.grid {
			margin-top: 0;
		}
		h1 {
			font-family: $bebasBold;
			color: #ffffff;
			p {
				margin-bottom: 0;
			}
		}
	}
	.grid {
		grid-gap: 50px;
		margin-top: 50px;
		.form-container {
			align-self: center;
		}
	}
	.text-content {
		color: #ffffff;
		hr{
			max-width: 100px;
			height: 4px;
			background: #008aaf;
			border-top: none;
			margin-left: 0;
		}
	}
}
footer {
	text-align: center;
	padding: 20px 0;
	color: #464646;
	.ts {
		font-family: 'poppins_lightitalic';
	}
}
.container {
	&.container--fullwidth-right {
		margin-right: unset;
		padding-right: 0;
	}
	&.container--fullwidth-left {
		margin-left: unset;
		padding-left: 0;
	}
}

.mdc-input-group {
	width: 100%;
	height: 48px;
	overflow: hidden;
	position: relative;
	background: transparent;
	
	.mdc-input {
		background: transparent;
		display: block;
		resize: none;
		height: 45px;
		width: 100%;
		padding-top: 20px;
		border: none;
		border-radius: 0; // For iOS
		color: #ffffff;
		font-size: 14px;
		transition: .3s ease;
		position: absolute;
		bottom: 0;
		z-index: 9;	
		background: transparent;

		&:focus, &:not(:placeholder-shown) {
			background-color: transparent!important;
			outline: none;
			~label {
				top: 0;
				font-size: 14px;
			}

			~ .bar:before {
				transform: translateX(0);
			}
		}
		&:-webkit-autofill {
			-webkit-box-shadow: 0 0 0px 1000px transparent inset;
			-webkit-text-fill-color: #ffffff !important;
			background: transparent;
		}
		&.error {
			color: red;

			+ label {
				color: red;

				+ .bar {
					background: red;
				}
			}
		}
	}

	.bar {
		background: #ffffff;
		content: '';
		width: 100%;
		height: 1px;
		transition: .3s ease;
		position: absolute;
		bottom: 0;

		&:before {
			position: absolute;
			width: 100%;
			background: #ffffff;
			transform: translateX(-100%);

		}
	}

	label {
		position: absolute;
		top: 20px;
		color: #ffffff;
		font-size: 14px;
		cursor: text;
		transition: .25s ease;
		text-transform: capitalize;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: 400;
	}
}
.button-container {
	margin-top: 20px;
}

.up {
	position: fixed;
	bottom: 50px;
	right: 50px;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	background: $blue;
	color: #ffffff;
	cursor: pointer;
	-webkit-box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.3);
	-moz-box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.3);
	box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.3);
	display: grid;

	i {
		font-size: 40px;
		align-self: center;
		justify-self: center;
	}
}
/* the slides */
.slick-slide {
    margin-left:27px;
  }

  /* the parent */
  .slick-list {
    margin-left:-27px;
  }
// Other Pages
.services{
	.services-sec{
		display:flex;
		
		min-height: 585px;

		.services-bg{
			position:absolute;
			width:100%;
			background-repeat: no-repeat !important;
			background-size: cover !important;
			background-position: center !important;
			opacity:0.2;
			min-height: 585px;
		}
		.service-col{
			margin-top:200px;
			z-index: 2;
			max-width:900px;
			.title{
				h5{
					text-transform: uppercase;
					font-family:$poppinsSemiBold;
					font-size: 23px;
				}
			}
			.service-row{
				display:flex;
				flex-direction: row;
				margin-top:20px;
				.caption{
					width:60%;
					p{
						line-height: 2;
						width:90%;					
						font-size:15px;
						strong{
							font-family:$poppinsSemiBold;
						}
					}
				}
				.inquiry-btn{
					width:40%;
					margin-top:10px;
					display:flex;
					justify-content: flex-end;
					button{
						width:70%;
						max-height: 75px;
						height:100%;
						display:flex;
						justify-content: space-between;
						background:#008aaf;
						border:0;
						color:white;
						font-family: $poppinsBold;
						padding: 0 30px;
						align-items: center;
						span{
							text-transform:uppercase;
						}
						i{
							font-weight: 300;
						}
					}
				}
			}
		}	
	}
	#2{
		margin-top:50px !important;
	}
	.services-item{
		margin-top:100px;

		// .container{
			.item-title{
				max-width: 1140px;
				margin:0 auto;
				h3{
					font-family: $bebasBold;
					font-size:35px;
					text-transform: uppercase;
					cursor:text;
				}
			}
			.item-body{
				display:flex;
				flex-direction:column;
				.item-buttons{
					max-width: 1140px;
					margin:0 auto;
					margin-top:30px;
					display:flex;
					width:100%;
					-moz-box-shadow: 5px 4px 15px 5px #ccc;
					-webkit-box-shadow: 5px 4px 15px 5px #ccc;
					box-shadow: 5px 4px 15px 5px #ccc;
					button{
						background: white;    
						border:0;
						border-left: 1px #eaeaea solid;
						padding:65px;
						text-transform: capitalize;
						outline:none;
						width:100%;
						&:hover,&:active{
							background:#008aaf;
							color:white;
						}
					}
					.active{
						background:#008aaf;
						color:white;
					}
					
				}
				
			}
			.item-content{
				display: flex;
				margin-top:100px;
				align-items: center;
				.slick-arrow{
					z-index: 2;
					max-height: 45px;
					background:transparent;
					border:0;
					// margin-top: 20%;
					outline: none;
				}
				div{

					h5{
						font-family: $poppinsSemiBold;
						font-size: 23px;
						padding: 0 30px;
					}
					p{
						font-size:17px;
						margin-top:30px;
						padding: 0 30px;
						line-height:25px;
					}
				}
			}
		// }
	}
	.real{
		margin-top:50px;
		.container{
			.title{
				h3{
					font-family: $bebasBold;
					font-size:35px;
					text-transform: uppercase;
					cursor:text;
				}
			}
			.content{
				margin-top:30px;
				max-width:1150px;
				margin-left:auto;
				margin-right:auto;
				display:flex;
				flex-direction: column;
				p{
					line-height: 2;
					font-size:15px;
				}
				.featured{
					display: flex;
					.left{
						display:flex;
						flex-direction: row;
						width:50%;
						padding:20px 0; 
						img{
							max-width: 150px;
    						max-height: 150px;
							margin-right: 5px;
							height:100%;
							object-fit: cover;
							object-position: center;
							border-radius: 50%;
						}
						i{
							font-size:25px;
							color:#a7a3a3;
						}
						p{
							padding:0 2%;
						}
						span{
							padding:0 2%;
							font-size:15px;
							color:#a7a3a3;
						}
						div{
							display:flex;
							flex-direction: column;
							width: 100%;
						}
					}
					.right{
						width:50%;
						padding:0 3%;
						video{
							width: 500px;
							outline: none;
						}
					}
				}
				.other{
					padding-top:50px;
					.row{
						display:flex;
						padding-bottom:30px;
						.testimony{
							display:flex;		
							width:50%;	
							img{
								width:140px;
								height:140px;
								margin-right: 5px;
								object-fit: cover;
								object-position: center;
								border-radius: 50%;
							}
							i{
								font-size:22px;
								color:#a7a3a3;
								padding-top:10px;
							}
							.caption{
								p{
									padding:0 10px;
									font-size:13px;
								}
								span{
									padding:0 10px;
									font-size:15px;
									color:#2e75b5;
								}
							}
						}
					}
				}
				
			}
		}
	}
	.assist{
		margin-top:50px;
		margin-bottom: 60px;
		.container{
			.assist-title{
				text-transform: capitalize;
				h3{
					font-family: $bebasBold;
					font-size:35px;
					text-transform: uppercase;
					cursor:text;
				}
			}
			.assist-content{
				margin-top:80px;
				.row{
					display:flex;
					flex-direction: row;
					div{
						width:50%;
						padding:15px 60px;
						border-bottom: 1px #dddddd solid;
						font-size:15px;
						&:first-child{
							border-right:1px #dddddd solid;
						}
					}
					&:last-child{
						div{
							border-bottom: 0px;
						}
					}
				}
			}
		}
	}
}
.about{
	.about-title{
		display:flex;
		.title-bg{
			background-position:center;
			background-size:cover;
			opacity: 0.3;
			width:100%;
			height:100%;
			
		}
		.gray{
			background: #2b2c2b;
			width:100%;
			height: 600px;
			position:absolute;

		}
		.container{
			z-index: 3;
			height:600px;
			display: flex;
			align-items: center;
			padding:0 100px;
			text-align:center;
			h2{
				font-family: $poppinsMedium;
				color:white;
				font-size:40px;
			}
		}
	}
	.about-info{
		margin-top:150px;
		.container{
			.info-row{
				display:flex;
				flex-direction: row;
				.about-tab{
					display:flex;
					width:30%;
					border-right:1px #dddddd solid;
					ul{
						width:100%;
						li{
							padding: 5px 5px 5px 130px;
							a{
								font-size:25px;
								font-family: $bebasBold;
								color:#e3e3e3;
								cursor: pointer;
								&:hover{
									color:black;
								}
							}
							.active{
								color:black;
								&:hover{
									color:black;
								}
							}
						}
					}
				}
				.about-content{
					width:70%;
					padding:0 50px;
					.slick-slide{
						min-height: 340px;
					}
					p{
						line-height: 2;
					}
					.about-us{
						display:block;
						strong{
							font-family: $poppinsSemiBold
						}
					}
					.vision{
						display:none;
						strong{
							font-family: $poppinsSemiBold
						}
					}
					.mission{
						display:none;
						strong{
							font-family: $poppinsSemiBold
						}
					}
					.active{
						display:block;
					}
				}
			}
		}
	}

	.management{
		margin-top:150px;
		.management-title{
			text-align: center;
			h3{
				font-family: $bebasBold;
				font-size:35px;
			} 
			p{
				padding:20px 150px;
				line-height: 2;
			}
		}
		.management-body{
			margin-top:50px;
			.row{
				margin-bottom:100px;
				display:flex;
				flex-direction: row;
				.picture{
					width:40%;
					border-right:1px#dddddd solid;
					display: flex;
					justify-content: center;
					img{
						object-fit: cover;
						object-position: center;
						max-width: 250px;
						max-height:250px;
						border-radius: 100%;
					}
				}
				.content{
					width:60%;
					padding: 0 50px;
					p{
						line-height: 2;
						strong{
							font-family: $poppinsSemiBold;
						}
					}
				}
			}
		}
	}

	.affiliation{
		margin-top:50px;
		.affiliation-title{
			text-align: center;
			h3{
				font-family: $bebasBold;
				font-size:35px;
			} 
		}	
		.affiliation-body{
			margin-top:100px;
			.row{
				// width:100%;
				display: flex;
				justify-content: space-around;
				min-height: 200px;
				img{
					object-fit: contain;
					object-position: center;
					max-width:200px;
					width:100%;
					height: 200px;
					-webkit-filter: grayscale(100%); 
					filter: grayscale(100%);
					&:hover{
						-webkit-filter: grayscale(0); 
						filter: grayscale(0);		
					}  
				}
			}
		}
	}
}
.slick-initialized,.slick-vertical{
	.slick-slide{
		outline: none;
		min-height: 170px;
	}
}


.news{
	.featured-article{
		.featured-bg{
			height: 585px;
			z-index: 2;
			color: white;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			background-size: cover !important;
			background-position: center !important;

		}
	}
	.featured-news{
		display:flex;
		&.no-data{
			background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
			min-height: 585px;
		}
		.div-slick{
			display:flex;
			width:100%;
			.featured-bg{
				position:absolute;
				width: 100vw;
				background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) ,url('../img/app/newseventsbg.png');
				background-size: cover !important;
				background-position: center !important;
				min-height: 585px;
			}
			.container{
				height:600px;
				z-index: 2;
				color:white;
				display: flex;
				align-items: center;
				
				.featured-content{
					max-width: 880px;
					h5{
					font-size:18px;
					}
					.featured-title{
						h3{
							font-family: $poppinsSemiBold;
							font-size:30px;
							line-height: 30px;
							letter-spacing: 1px;
							// text-transform: 
						}
					}
					.featured-caption{
						min-height: 142px;
						div{
							max-height: 60px;
							margin-bottom: 30px;
							overflow: hidden;
							p{
								line-height: 2;
								width:60%;
								font-size:15px;
								*{
									color:white !important;
									text-align: left !important;
								}
							}
						}
						a{
							background:transparent;
							border-radius:5px;
							border:1px solid white;
							padding:15px 25px;
							font-family: $poppinsSemiBold;
							cursor: pointer;
							&:hover,&:active{
								background:white !important;
								color:black;
							}
						}
					}
				}
			}
		}
		.slick-dots {
			position: absolute;
			bottom: 20px;
			left: 50%;
			display: flex;
			>li {
				margin: 0 5px;
				background-color: #fff;
				border-radius: 50%;
				width: 10px;
				height: 10px;
				opacity: .7;
				cursor: pointer;
				button {
					display: none;
				}
				&.slick-active {
					background-color: #008aaf;
					opacity: 1;
				}
			}
		}
	}
	.news-body{
		margin-top:50px;
		.container{
			padding:0;
			.title{
				text-align: center;
				h3{
					font-family:$bebasBold;
					font-size:35px;
				}
				.filter{
					display:flex;
					justify-content: flex-start;
					select{
						padding: 5px 10px;
						border-radius: 5px;
						font-size:16px;
						font-family: $poppinsRegular;
					}
				}
			}
			.body{
				margin-top:60px;
				.row{
					display:flex;
					flex-direction: row;
					// justify-content: space-between;
					margin-bottom:80px;
					.card{
						margin:0 10px;
						max-width: 380px;
						padding:10px 30px;
						background-repeat: no-repeat !important;
						background-size:cover  !important;
						background-position: center !important;
						color:white;
						width: 100%;
						box-shadow: inset 0px 0px 130px rgba(0,0,0,1.5);
						// border-radius: 30px;
						.content{
							min-height: 226px;
							display: flex;
							flex-direction: column;
							justify-content: flex-end;
							p{
								margin:0;
								text-transform: uppercase;
							}
							h5{
								font-family: $poppinsSemiBold;
								font-size:18px;
								max-width: 280px;
								margin-top: 5px;
							}
							a{
								font-family: $poppinsSemiBold;
								border: 1px #ffff solid;
								border-radius: 10px;
								max-width:95px;
								padding: 10px 5px;
								font-size: 11px;
								text-align: center;
								margin:10px 0;
							}
						}
					}
				}
				.row-n {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					grid-gap: 50px 30px;
					.card-n {
						background-color: #fff;
						.image {
							min-height: 250px;
							width: 100%;
							background-position: center!important;
							background-size: cover!important;
							background-repeat: no-repeat!important;
						}

						.content{
							padding: 20px 15px;
							p{
								margin:0;
								text-transform: uppercase;
								font-size: 16px;
							}
							.date {
								font-size: 13px;
								color: #7f7f7f;
							}
							h5{
								font-family: $poppinsSemiBold;
								font-size:18px;
								margin: 10px 0 15px 0;
							}
							a{
								font-family: $poppinsSemiBold;
								border: 1px #222 solid;
								border-radius: 10px;
								max-width:95px;
								padding: 10px 5px;
								font-size: 11px;
								text-align: center;
								margin:10px 0;
								transition: all .3s;
								&:hover {
									background: #2e75b5!important;
									color: #fff;
									border: 1px #2e75b5 solid;
									cursor: pointer;
								}
							}
						}
					}
				}
				@media(max-width:1024px) {
					.row-n {
						grid-template-columns: 1fr 1fr;
					}
				}
				@media(max-width: 768px) {
					display: flex;
					justify-content: center;
					.row-n {
						grid-template-columns: 380px;
					}
				}
				@media(max-width: 469px) {
					.row-n {
						grid-template-columns: 300px;
					}
				}
			}
			.news-pagination {
				display: flex;
				justify-content: center;
			}
		}
	}
	.view-body{
		padding-top:70px;
		margin-left:auto;
		margin-right:auto;
		display:flex;
		.container{
			margin-bottom: 40px;
			display: flex;
			.body{
				// max-width: 770px;
				width:100%;
			}
			.title{
				h2{
					font-family: $poppinsSemiBold;
				}
				span{
					color:#9b9b9b;
				}
			}
			.content{
				padding-top:50px;
				font-size:16.5px;
				line-height: 2;
				p{
				}
			}
		}
		
		.social-media{
			display:flex;
			flex-direction: column;
			max-width:100vw;
			text-align: center;
			width:100%;
			align-items: flex-end;
			position: absolute;
			a{
				max-width:40px;
				width:100%;
				padding:10px;
				i{
					color:white;
				}
			}
			.fb{
				background:#3b5998 !important;
				margin-top: 120px;
			}
			.pinterest{
				background:#bd081c !important;
			}
			.twitter{
				background:#1da1f2 !important;
			}
			.ig{
				background:#db4807 !important;
			}
		}
	}
	
	
	
	hr{
		max-width:1170px;
		margin-left:auto;
		margin-right:auto;
		border-top: 3px solid #eeeeee;

	}
	
	.no-data {
		text-align: center;
		padding-bottom: 99px;
		&.none {
			display: none;
		}

	}
}

 

.property-locator {
	.banner-section {
		position: relative;
		.banners {	
			.slick-dots {
				position: absolute;
				bottom: 20px;
				left: 50%;
				display: flex;
				>li {
					margin: 0 5px;
					background-color: #fff;
					border-radius: 50%;
					width: 10px;
					height: 10px;
					opacity: .7;
					cursor: pointer;
					button {
						display: none;
					}
					&.slick-active {
						background-color: #008aaf;
						opacity: 1;
					}
				}
			}
			.banner {
				padding: 100px 0 200px;
				min-height: 100vh;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				position: relative;
				display: flex;
				align-items: center;
				&::after {
					content: ' ';
					background-color: rgba(0, 0, 0, 0.4);
					position: absolute;
					top: 0;
					right: 0;
				bottom: 0;
					left: 0;
				}
				.content {
					color: #fff;
					position: relative;
					z-index: 10;
					max-width: 800px;
					.title {
						font-size: 30px;
						font-family: "poppins_semiboldregular";
						line-height: 30px;
						letter-spacing: 1px;
					}
					.description {
						font-size: 17px;
						margin-top: 5px;
						margin-bottom: 10px;
					}
					>a {
						border: 1px solid #fff;
						padding: 15px 25px;
						display: inline-block;
					}
				}
			}
		}
		
		.property-filter {
			position: absolute;
			bottom: 150px;
			width: 100%;
			.filter {
				background-color: #fff;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 25px 30px;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
				#filter-btn {
					padding: 13px 38px;
					border: none;
					background: #ff004c;
					color: #fff;
					font-family: $poppinsBold;
					font-size: 12px;
					i {
						display: none;
					}
					&.loading {
						i {
							display: block;
							  animation: spin 2s linear infinite;
							  font-size: 20px;
						}
						@keyframes spin {
							0% { transform: rotate(0deg); }
							100% { transform: rotate(360deg); }
						}
						span {
							display: none;
						}
					}
				}
				label {
					white-space: nowrap;
				}
			}
		}
	}

	.select2-container--default .select2-selection--single {
		background: none;
		border: none;
		border-bottom: 1px solid red;
		border-radius: 0;
	}

	.properties-section {
		background: #eeeeee;
		padding: 60px 0;
		#properties-section {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
		}
		.cards {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-rows: auto;
			grid-gap: 50px 30px;
			.card {
				background: #fff;
				// .img {
				// 	width: 100%;
				// 	height: 285px;
				// 	cursor: pointer;
				// 	overflow: hidden;
				// 	>img {
				// 		object-fit: cover;
				// 		object-position: center;
				// 		transition: transform .5s;
				// 		width: 100%;
				// 		height: 100%;
				// 		&:hover {
				// 			transform: scale(1.1);
				// 		}
				// 	}
				// }
				.img-holds {
					position: relative;
					width: 100%;
					height: 285px;
					cursor: pointer;
					// overflow: hidden;
					.img-hold {
						overflow: hidden;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						.img-sliders {
							width: 100%;
							.img-slider {
								min-height: 285px;
								background-position: center;
								background-size: cover;
								background-repeat: no-repeat;
							}
						}
					}
				}
				.content {
					padding: 11px 17px 23px 17px;
					.title {
						font-size: 17px;
						font-weight: bold;
					}
					.address {
						font-size: 16px;
					}
					.price {
						margin: 15px 0;
						font-weight: bold;
					}
					.tags {
						display: grid;
						grid-template-columns: auto auto auto;
						grid-template-rows: auto;
						grid-gap: 15px;
						.tag {
							border: none;
							background: #f6f6f6;
							font-size: 11px;
							padding: 5px;
							border-radius: 10px;
							display: flex;
							align-items: center;
							justify-content: center;
							text-align: center;
						}
					}
				}
			}
		}
		.modal {
			.modal-dialog {
				.modal-content {
					border-radius: 0;
					background: none;
					box-shadow: none;
					border: none;
					.property-banners {
						box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
						.property-banner {
							background-size: cover;
							background-position: center;
							background-repeat: no-repeat;
							min-height: 400px;
						}
					}
					.property-nav-banners {
						margin-top: 10px;
						.property-nav-banner {
							box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
							background-size: cover;
							background-position: center;
							background-repeat: no-repeat;
							min-height: 100px!important;
							margin: 0 4px;
						}
					}
				}
			}
		}
		.load-more {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 50px;
			>button {
				border: none;
				background: $blue;
				color: #fff;
				padding: 15px 25px;
			}
		}
		.no-data {
			text-align: center;
			&.none {
				display: none;
			}
		}
	}
}

@media(max-width:1199px){

	.news{
		.news-body{
			.container{
				.body{
					.row{
						.card{
							max-width: 300px;
						}
					}
				}
			}
		}
	}
	.services{
		.services-item{
			.container{
				.item-body{
					.item-buttons{
						max-width: 1140px;
						button{
							padding:55px 6%;
						}
					}
				}
			}
		}
		.real{
			.container{
				.content{
					.featured{
						.right{
							video{
								padding:0 30px;
							}
						}
						.left{
							img{
								max-width: 140px;
								max-height: 140px;
							}
						}
					}
					.other{
						.row{
							.testimony{
								img{
									max-width: 140px;
									
									max-height: 140px;
								}
								.caption{
									p{
										padding:0 15px;
									}
									span{
										padding:0 15px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.about{
		.affiliation{
			.affiliation-body{
				.row{
					text-align: center;
					img{
						// max-width:150px;
						height: 150px;
					}
				}
			}
		}
	}
}

@media (max-width:1030px){
	.services{
		.real{
			.container{
				.content{
					.featured{
						.right{
							video{
								padding:0 35px;
								max-width: 440px;
							}
						}
					}
				}
			}
		}
	}
	.news{
		.view-body{
			.container{
				.body{
					padding:0 30px;
				}
			}
		}
	}
}
@media(max-width:1024px){
	.services{
		.services-sec{
			.container{
				width:100%;
			}
		}
		
		.market{
			.container{
				width:100% !important;
				.market-body{
					flex-direction: row;
					.market-buttons{
						flex-direction:column;
					}
					.market-content{
						margin:0 auto;
						h5{
							text-align: center;
						}
						p{
							width:100%;
						}
					}
				}
			}
		}
		.valuation{
			.container{
				width:100% !important;
				.valuation-body{
					flex-direction: row;
					.valuation-buttons{
						flex-direction:column;
						button{
							padding:65px 25px;
						}
					}
				}
			}
		}
	}
	.property-locator {
		.properties-section {
			.cards {
				grid-template-columns: 1fr 1fr;
				grid-template-rows: auto;
				grid-gap: 50px 30px;
				.card {
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}
}

.collapse{
	&.in{
		display:block;	
		transform: translateX(0) !important;
		height: 100% !important;
	}
}
.sidebar{
	background:white;
	max-width:330px;
	width:100%;
	left:0;
	z-index:10000;
	position: fixed;;
	height: 100%;
	-webkit-box-shadow: 5px 0 5px -2px #888;
          box-shadow: 5px 0 5px -2px #888;
	.side-body{
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		button{
			background:transparent;
			border:3px rgb(46, 117, 181) solid;
			border-radius:50%;
			padding: 10px 15px;
			margin:50px 0px 50px 70px;
			i{
				color: rgb(46, 117, 181);
			}
			&:hover,&:active,&:focus{
				color:	#ee2d24;
				border:3px #ee2d24 solid;
				i{
					color:#ee2d24;
				}
			}
		}
		.nav{
			width:100%;
			display:flex;
			align-items:center;
			flex-direction: column;
			li{
				width:70%;
				a{
					color:rgb(46, 117, 181);
					padding: 25px;
					font-family: $poppinsBold;
					&:hover,&:active,&:focus{
					color:	#ee2d24;
					}
				}
			}
		}
	}
}
@media(max-width:992px){
	.navbar{
		.container{
			padding:0;
			margin:0;
		}
		.navbar-toggle{
			display:block;
			color:white;
			&:hover,&:focus{
				opacity: 0.8;
				background:transparent;
				// color:white;
			}
			.icon-bar{
				background-color:white;
			}
		}
		.navbar-header{
			margin:0;
			float:none !important;
			width:100%;
			display:flex;
			.navbar-brand{
				display: flex;
				justify-content: flex-start;
			}
			button{
				display:flex;
				flex-direction: column;
				align-items: flex-end;
				&:hover{
					span{
						color:grey;
					}
				}
			}
		}
		.left{
			display:none;
		}
	}
	.navbar-collapse{
		&.collapse{
			display:none !important;
		}
	}
	.news{
		.news-body{
			.container{
				.body{
					.row{
						.card{
							max-width: 240px;
						}
					}
				}
			}
		}
	}
	.services{
		.services-item{
			.container{
				.item-body{
					.item-buttons{
						button{
							padding:55px 5%;
						}
					}
				}
			}
		}
	}
	.about{
		.about-info{
			.container{
				.info-row{
					.about-content{
						.slick-slide{
							min-height: 445px;
						}
					}
				}
			}
		}
		.management{
			.management-title{
				p{
					padding:20px 0;
				}
			}
		}
	}
	
}

@media(max-width:975px){
	.services{
		.services-sec{
			.service-col{
				.service-row{
					.inquiry-btn{
						button{
							padding:0 15px;
						}
					}
				}
			}
		}
	}
}

@media(max-width:880px){
	.services{
		.real{
			.container{
				.content{
					.featured{
						flex-direction: column-reverse;
						align-items: center;
						.right{
							width:unset;
							video{
								max-width: 500px;
							}
						}
						.left{
							width:100%;
						}
					}
				}
			}
		}
	}
}

@media(max-width:830px){
	.services{
		.services-sec{
			.service-col{
				margin-top:150px;
				min-height: 400px;
				.title{
					text-align: center;
				}
				.service-row{
					flex-direction: column;
					align-items: center;
					margin-top:40px;
					.caption{
						width:100%;
						display: flex;
						flex-direction: column;
						align-items: center;
						p{
							width: 50%;
						}
					}
					.inquiry-btn{
						margin-top:0px;
						max-width:250px;
						button{
							width:100%;
							height: 50px;
						}
					}
				}
			}
		}
		.assist{
			.container{
				.assist-content{
					margin-top: 60px;
					margin-bottom:30px;
					display:flex;
					align-items: center;
					flex-direction: column;
					.row{
						max-width: 720px;
						width: 100%;					
						div{
							padding: 5px 60px;
						}
					}
				}
			}
		}
	}
}

@media(max-width:800px){
	.news{
		.news-body{
			.container{
				.body{
					.row{
						flex-direction: column;
						align-items: center;
						margin-bottom:0;
						.card{
							max-width: 410px;
							margin-bottom:30px;
						}
					}
				}
			}
		}
	}
	
	.services{
		.real{
			.container{
				.content{
					.other{
						.row{
							padding-bottom:0;
							flex-direction: column;
							.testimony{
								width:unset;
								padding:0 20px;
							    padding-bottom: 20px;
							}
						}
					}
				}
			}
		}
	}
}

@media(max-width: 768px) {
	.navbar-default{
		.navbody{
			.navbar-header{
				
			}
			.left{
				flex-direction: column;
				align-items: flex-end;
				// .social{
				// 	width:35%;
				// }
			}
		}
	}
	.home-page {
		.services-section {
			.container {
				.grid {
					grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
				}    
				.services {
					.grid {
						grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
					}
				}
			}
			
		}
	}
	.property-locator {
		.banner-section {
			.banners {
				.banner {
					align-items: flex-start;
					.container {
						margin-left: 0;
					}
					.content {
						color: #fff;
						position: relative;
						z-index: 10;
						.title {
							font-size: 25px;
						}
						.description {
							font-size: 17px;
						}
						>a {
							border: 1px solid #fff;
							padding: 10px 15px;
							display: inline-block;
						}
					}
				}
			}
			.property-filter {
				left: 50%;
				transform: translateX(-50%);
				width: auto;
				bottom: 75px;
				.filter {
					flex-direction: column;
					.select2 {
						margin-bottom: 10px;
					}
				}
			}
		}
		.properties-section {
			.cards {
				grid-template-columns: 380px;
				grid-gap: 50px 30px;
			}
		}
	}
	.about{
		.about-info{
			.container{
				.info-row{
					flex-direction: column;
					.about-tab{
						border-right: 0;
						border-bottom: 1px #dddddd solid;
						margin-bottom: 10px;
						width: 100%;
						hr {
							display: block;
						}
						ul{
							display: flex;
							justify-content: center;
							li{
								white-space: nowrap;
								padding: 5px 30px;
							}
						}
					}
					.about-content {
						width: 100%;
					}
				}
			}
		}
		.management{
			.management-body{
				.row{
					.picture{
						img{
							max-width: 200px;
							max-height: 200px;
						}
					}
				}
			}
		}
	}
}

@media(max-width:745px){
	.about{
		.about-info{
			.container{
				.info-row{
					.about-content{
						.slick-slide{
							min-height: 830px;
						}
					}
				}
			}
		}
	}
}

@media(max-width:715px){
	.services{
		.services-item{
			.container{
				.item-body{
					.item-buttons{
						button{
							padding: 55px 4%;
						}
					}
				}
			}
		}	
	}
}

@media(max-width:650px){
	.about{
		.management{
			.management-body{
				.row{
					.picture{
						width: 50%;
						img{
							max-width: 200px;
							max-height: 200px;
						}
					}
				}
			}
		}
	}
	.news{
		.featured-news{
			.container{
				.featured-content{
					.featured-caption{
						p{
							width:100%;	
						}
					}
				}
			}
		}
	}
}

@media(max-width:600px){
	.services{
		.services-sec{
			.service-col{
				margin-top:110px;
				.service-row{
					.inquiry-btn{
						width:100%;
						max-width:180px;
					}
				}
			}
		}
		.services-item{
			.container{
				.item-body{
					.item-buttons{
						button{
							padding: 55px 3%;
						}
					}
				}
			}
		}
	}
}

@media(max-width:500px){
	.news{
		.featured-news{
			.container{
				.featured-content{
					h5{
						font-size:15px;
					}
					.featured-title{
						h3{
							font-size:25px;
						}
					}
					.featured-caption{
						p{
							font-size:13px;
						}
						button{
							font-size:13px;
						}
					}
				}
			}
		}
		.news-body{
			.container{
				.title{
					h3{
						font-size:30px;
					}
				}
				.body{
					.row{
						.card{
							.content{
								p{
									font-size:13px;
								}
								h5{
									font-size:15px;
								}
							}
						}
					}
				}
			}
		}
		.view-body{
			.container{
				.body{
					.title{
						h2{
							font-size:25px;
						}
						span{
							font-size:13px;
						}
					}
				}
				.content{
					p{
						font-size:14px;
					}
				}
			}
		}
	}
	.about{
		.about-info{
			.container{
				.info-row{
					.about-tab{
						ul{
							li{
								padding: 5px 5px 5px 20px;
								a{
									font-size:23px;
								}
							}
						}
					}
					.about-content{
						p{
							font-size:13px;
							line-height: 1.8;
						}
						.slick-slide{
							min-height: 950px;
						}
					}
				}
			}
		}
		.management{
			.management-title{
				h3{
					font-size:30px;
				}
				p{
					font-size:13px;
				}
			}
			.management-body{
				.row{
					flex-direction: column;
					align-items:center;
					.picture{
						padding: 20px;
						margin: 20px;
						border:0;
						border-bottom: 1px #dddddd solid;
					}
					.content{
						width: 100%;
						padding:0 10%; 
						p{
							font-size:13px;
							line-height: 1.8;
						}
					}
				}
			}
		}
		.affiliation{
			.affiliation-title{
				h3{
					font-size:30px;
				}
			}
		}
		.about-title{
			.container{
				padding: 0 5%;
				h2{
					font-size:35px;
				}
			}
		}
	}
	.services{
		.services-sec{
			.service-col{
				.title{
					h5{
						font-size:20px;
					}
				}
				.service-row{
					.caption{
						p{
							font-size:13px;
							line-height: 1.8;
							width:100%;
						}
					}
					.inquiry-btn{
						button{
							span{
								font-size: 12px;
							}
						}
					}
				}
			}
		}
		.services-item{
			.container{
				.item-title{
					h3{
						font-size:30px;
					}
				}
				.item-body{
					.item-content{
						div{
							h5{
								font-size: 20px;
							}
							p{
								font-size: 13px;
							}
						}
					}
					.item-buttons{
						button{
							font-size:13px;
						}	
					}
				}
			}
		}
		.real{
			.container{
				.title{
					h3{
						font-size:30px;
					}
				}
				.content{
					p{
						font-size:13px;
						line-height: 1.8;
					}
					.featured{
						.left{
							span{
								font-size:13px;
							}
						}
					}
				}

			}
		}
		.assist{
			.container{
				.assist-title{
					h3{
						font-size:30px;
					}
				}
				.assist-content{
					.row{
						div{
							font-size:13px;
							padding: 5px 40px;
						}
					}
				}
			}
		}
	}
}

@media(max-width:480px){
	.services{
		.real{
			.container{
				.content{
					.featured{
						.right{
							video{
								max-width: 360px;
							}
						}
					}
				}
			}
		}
	}
}

@media(max-width: 469px) {
	.container {
		&.container--fullwidth-right {
			margin-right: auto;
			padding-right: 15px;
		}
		&.container--fullwidth-left {
			margin-left: auto;
			padding-left: 15px;
		}
	}
	.home-page {
		.hero-banner {
			.container {
				.grid {
					grid-gap: 0;
				}
				.text-content {
					margin-top: 0;
					h1 {
						font-size: 3.5em;
					}
				}
				.image {
					height: 350px;
				}
			}
		}
		.services-section {
			padding-top: 50px;
			.container {
				.grid {
					grid-template-columns: repeat(auto-fit, minmax(1fr, 1fr));
				}    
				.services {
					.grid {
						grid-template-columns: repeat(auto-fit, minmax(1fr, 1fr));
					}
				}
			}
		}
		.profile-section {
			padding-top: 0px;
			.container {
				.image {
					height: 350px;
				}
				.grid {
					grid-gap: 0;
				}
			}
		}
		.management-section {
			.managers {
				.manager {
					grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
					.image {
						margin-bottom: 30px;
						align-self: center;
						justify-self: center;
					}
				}
			}
		}
	}


	.property-locator {
		.properties-section {
			.cards {
				grid-template-columns: 300px;
			}
		}
	}
}

@media(max-width:415px){
	.about{
		.about-info{
			.container{
				.info-row{
					.about-content{
						.slick-slide{
							min-height: 1065px;
						}
					}
				}
			}
		}
	}

	.services{
		.real{
			.container{
				.content{
					.other{
						.row{
							.testimony{
								flex-direction: column;
								img{
									align-self: center;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media(max-width:400px){
	.about{
		.about-info{
			.container{
				.info-row{
					.about-content{
						.slick-slide{
							min-height: 1295px;
						}
					}
				}
			}
		}
	}
}
@media(max-width:360px){
	.about{
		.about-info{
			.container{
				.info-row{
					.about-content{
						.slick-slide{
							min-height: 1630px !important;
						}
					}
				}
			}
		}
	}
}
@media(max-width:320px){
	.about{
		.affiliation{
			.affiliation-body{
				.row{
					a{
						display: flex;
						justify-content: center;
					}
					img{
						max-width: 150px;
					}
				}
			}
		}
	}
}

.description {
	font-size: 17px !important;
	>p {
		font-size: 17px !important;
	}
	&.white {
		color: #fff;
	}
}

.blank {
	height: 100px;
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	overflow: hidden;
	max-width: 100%;
}
.embed-container iframe, .embed-container object, .embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.testimonial {
	.featured-n {
		display: grid;
		grid-template-columns: 1fr 1fr;
		.content-n {
			display: flex;
			img {
				width: 140px;
				height: 140px;
				object-fit: cover;
				object-position: center;
				border-radius: 50%;
			}
			.quote {
				display: flex;
				&:before {
					content: '\f10d';
					font-family: 'Font Awesome 5 Pro';
					font-weight: 900;
					display: inline;
					color: #a7a3a3;
					font-size: 21.4px;
					padding-top: 4px;
					padding-right: 13px;
				}
				.description {
					span {
						font-size: 15px;
						color: #2e75b5;
					}
				}
			}
		}
		.video {
			padding-left: 30px;
		}
	}

	@media(max-width:800px){
		.featured-n {
			grid-template-columns: 1fr;
			.content-n {
				.quote {
				}
			}
			.video {
				padding-left: 0;
				padding-top: 30px;
			}
		}
	}
}

#siteModal {
	.modal-header, .modal-footer {
		border: none;
		.close {
			margin-top: 0;
			position: absolute;
			right: 15px;
			top: 15px;
		}
	}
	.logo {
		img {
			max-width: 300px;
		}
	}
	.modal-body {
		padding: 15px 30px;
	}
	textarea {
		resize: none;
	}
}

.link-red {
	color: #ee2d25;
	transition: all ease-in-out 0.2s;
	cursor: pointer;
	&:hover {
		color: #ee2d25;
		font-size: 16px;
	}
}