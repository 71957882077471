@font-face {
    font-family: 'bebas_neuethin';
    src: url('../fonts/bebasneue_thin-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'bebas_neueregular';
    src: url('../fonts/bebasneue_regular-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'bebas_neuelight';
    src: url('../fonts/bebasneue_light-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'bebas_neuebook';
    src: url('../fonts/bebasneue_book-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'bebas_neuebold';
    src: url('../fonts/bebasneue_bold-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'bebas_neueregular';
    src: url('../fonts/bebasneue-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppins_semibolditalic';
    src: url('../fonts/poppins-semibolditalic-webfont.woff2') format('woff2'),
         url('../fonts/poppins-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppins_semiboldregular';
    src: url('../fonts/poppins-semibold-webfont.woff2') format('woff2'),
         url('../fonts/poppins-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsregular';
    src: url('../fonts/poppins-regular-webfont.woff2') format('woff2'),
         url('../fonts/poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppins_mediumitalic';
    src: url('../fonts/poppins-mediumitalic-webfont.woff2') format('woff2'),
         url('../fonts/poppins-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppins_mediumregular';
    src: url('../fonts/poppins-medium-webfont.woff2') format('woff2'),
         url('../fonts/poppins-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsbold_italic';
    src: url('../fonts/poppins-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/poppins-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppins_lightitalic';
    src: url('../fonts/poppins-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/poppins-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppins_lightregular';
    src: url('../fonts/poppins-light-webfont.woff2') format('woff2'),
         url('../fonts/poppins-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsbold';
    src: url('../fonts/poppins-bold-webfont.woff2') format('woff2'),
         url('../fonts/poppins-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}